import config from '../config'

import { PlatformClient } from '../types'
import * as oauth from './oauth'
import * as auth from './auth'
import * as users from './users'
import * as integrations from './integrations'
import * as appInstance from './app-instance'
import * as dataTable from './data-table'
import * as telephony from './telephony'
import * as architectFlows from './architect-flows'
import * as architectIvrs from './architect-ivrs'
import * as scripts from './scripts'
import * as queue from './queue'

const getGenesysConfigState = async (platformClient: PlatformClient) => {
    const getUsersMe = users.doGetUsersMe(platformClient)
    const getInstalledObjects =
        integrations.doGetInstalledObjects(platformClient)
    return Promise.all([getUsersMe, getInstalledObjects]).then(
        ([user, installedObjects = { entities: [] }]) => {
            const installedIntegrations = installedObjects.entities.filter(
                (entity) => {
                    return (
                        entity.integrationType.id == config.appName ||
                        entity.name == config.sandboxAppName
                    )
                }
            )
            return { installedIntegrations, user }
        }
    )
}

const validateProductAvailability = (platformClient: PlatformClient) => {
    return integrations
        .doGetAvailableIntegrationTypes(platformClient)
        .then((r) => {
            return !!r.length
        })
}

export {
    oauth,
    auth,
    users,
    appInstance,
    dataTable,
    telephony,
    integrations,
    architectFlows,
    architectIvrs,
    scripts,
    queue,
    getGenesysConfigState,
    validateProductAvailability
}
