import React from 'react'
import { captureException } from '@thriveglobal/thrive-web-core'
import { Models } from 'purecloud-platform-client-v2'
import { clearItems, getItem, saveItem } from '../../sessionStorage'
import * as apis from './apis'
import {
    APP_INSTALLED_STATE,
    client,
    GENESYS_ACCESS_TOKEN,
    GENESYS_ACCESS_TOKEN_EXPIRY,
    GENESYS_CLIENT_AUTH_PATH,
    GENESYS_HELP_PATH,
    GENESYS_INSTALL_PATH,
    GENESYS_PILOT_PATH,
    GENESYS_RESET_PATH,
    GenesysContext,
    GenesysContextUpdater,
    platformClient
} from './GenesysContext'
import config from './config'

import { getGenesysEnvironment } from './apis/auth'
import { getExistingOauthClients } from './apis/oauth'

const GenesysProvider = ({ children }) => {
    const [user, setUser] = React.useState<Models.UserMe>({
        id: undefined
    } as Models.UserMe) // TODO: fix. missing required filed version
    const [relay, setRelay] = React.useState('')
    const [installedStatus, setInstalledStatus] = React.useState(
        APP_INSTALLED_STATE.UNKNOWN
    )
    const [installedObjects, setInstalledObjects] = React.useState([])
    const [isProductAvailable, setIsProductAvailable] = React.useState(false)

    const cachedToken = getItem(GENESYS_ACCESS_TOKEN)
    const cachedTokenExpiry = parseInt(getItem(GENESYS_ACCESS_TOKEN_EXPIRY))
    client?.setEnvironment(getGenesysEnvironment())

    const checkInstallationState = () => {
        apis.getGenesysConfigState(platformClient)
            .then(({ user, installedIntegrations }) => {
                if (!user) {
                    window.location.replace(
                        `${window.location.origin}${GENESYS_HELP_PATH}`
                    )
                } else {
                    getExistingOauthClients(platformClient).then(
                        (oauthClients) => {
                            const installedState = oauthClients.length
                                ? APP_INSTALLED_STATE.INSTALLED
                                : APP_INSTALLED_STATE.NOT_INSTALLED
                            setInstalledStatus(installedState)
                            setUser(user)
                            setInstalledObjects(installedIntegrations)
                        }
                    )
                    setIsProductAvailable(true)
                }
            })
            .catch((err) => captureException(err))
    }

    if (
        window.location.pathname.indexOf(GENESYS_HELP_PATH) !== 0 &&
        window.location.pathname.indexOf(GENESYS_RESET_PATH) !== 0 &&
        window.location.pathname.indexOf(GENESYS_CLIENT_AUTH_PATH) !== 0
    ) {
        if (
            cachedToken !== 'undefined' &&
            cachedTokenExpiry > Date.now() &&
            installedStatus === APP_INSTALLED_STATE.UNKNOWN
        ) {
            client?.setAccessToken(cachedToken)
            if (window.location.pathname.indexOf(GENESYS_RESET_PATH) !== 0) {
                checkInstallationState()
            }
        } else if (installedStatus !== APP_INSTALLED_STATE.UNKNOWN) {
            if (
                installedStatus === APP_INSTALLED_STATE.NOT_INSTALLED &&
                window.location.pathname.indexOf(GENESYS_INSTALL_PATH) !== 0
            ) {
                window.location.replace(
                    `${window.location.origin}${GENESYS_INSTALL_PATH}`
                )
            }
        } else if (installedStatus === APP_INSTALLED_STATE.UNKNOWN) {
            apis.auth
                .doImplicitGrant(
                    client,
                    window.location.protocol +
                        '//' +
                        window.location.host +
                        window.location.pathname
                )
                .then((tokenResponse) => {
                    clearItems()
                    saveItem(GENESYS_ACCESS_TOKEN, tokenResponse.accessToken)
                    saveItem(
                        GENESYS_ACCESS_TOKEN_EXPIRY,
                        tokenResponse.tokenExpiryTime
                    )

                    checkInstallationState()
                })
                .catch((err) => captureException(err, `grant error`))
        }
    }

    return (
        <GenesysContextUpdater.Provider
            value={{
                setUser,
                setInstalledObjects,
                setRelay,
                setInstalledStatus,
                setIsProductAvailable
            }}
        >
            <GenesysContext.Provider
                value={{
                    platformClient: platformClient,
                    client: client,
                    user,
                    installedStatus,
                    installedObjects,
                    relay,
                    isProductAvailable,
                    token: cachedToken
                }}
            >
                {children}
            </GenesysContext.Provider>
        </GenesysContextUpdater.Provider>
    )
}

export default GenesysProvider
