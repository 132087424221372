export enum ROUTES {
    HOME = '/calls',
    GENESYS = '/calls/genesys',
    GENESYS_INSTALL = '/calls/genesys/install',
    GENESYS_UNINSTALL = '/calls/genesys/uninstall',
    GENESYS_RESET = '/calls/genesys/reset',
    GENESYS_ADMIN = '/calls/genesys/admin',
    GENESYS_REPORTING = '/calls/genesys/admin/reporting',
    GENESYS_ENGAGEMENT = '/calls/genesys/admin/reporting/engagement',
    GENESYS_AGENT_OVERVIEW_REPORTING = '/calls/genesys/admin/reporting/agent/:id',
    GENESYS_OAUTH = '/calls/genesys/oauth',
    GENESYS_CLIENT_AUTH = '/calls/genesys/client-auth',
    GENESYS_HELP = '/calls/genesys/help',
    PILOT_RESET = '/calls/pilot/reset',
    PILOT_HELP = '/calls/pilot/help',
    NICE = '/calls/nice',
    NICE_RESET = '/calls/nice/reset',
    NICE_ADMIN = '/calls/nice/admin',
    NICE_REPORTING = '/calls/nice/admin/reporting',
    NICE_AGENT_OVERVIEW_REPORTING = '/calls/nice/admin/reporting/agent/:id',
    NICE_HELP = '/calls/nice/help',
    WEBEX_RESET = '/calls/webex/reset',
    WEBEX_ADMIN = '/calls/webex/admin',
    WEBEX_REPORTING = '/calls/webex/admin/reporting',
    WEBEX_INSTALL = '/calls/webex/install',
    WEBEX_HELP = '/calls/webex/help'
}
