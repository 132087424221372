import { PlatformClient } from '../types'

/* eslint-disable */
const doGetUsersMe = (platformClient: PlatformClient) => {
    const userApi = new platformClient.UsersApi()
    const opts = {
        expand: ['authorization']
    }
    return userApi
        .getUsersMe(opts)
        .catch((err) => console.log('users err', err))
}

export { doGetUsersMe }
