/* eslint-disable */
import config from '../config'
import { captureException } from '@thriveglobal/thrive-web-core'
import { PlatformClient } from '../types'
import { Models } from 'purecloud-platform-client-v2'

const getExistingOauthClients = (platformClient: PlatformClient) => {
    const oAuthApi = new platformClient.OAuthApi()
    return oAuthApi.getOauthClients().then((data) => {
        return data.entities.filter((entity) => {
            if (entity.name) return entity.name.startsWith(config.prefix)
            else return false
        })
    })
}

// In order to delete a client, it must be inactive
const setInactiveClients = (platformClient: PlatformClient) => {
    const oAuthApi = new platformClient.OAuthApi()
    const state = 'inactive'
    return getExistingOauthClients(platformClient).then((instances) => {
        const clientStates = []

        if (instances.length > 0) {
            instances
                .map((entity) => entity.id)
                .forEach((cid) => {
                    clientStates.push(
                        oAuthApi.putOauthClient(cid, {
                            state
                        } as Models.OAuthClientRequest) // TODO: Fix type
                    )
                })
        }
        return Promise.all(clientStates).catch((err) => {
            captureException(err, `Validation failed`)
        })
    })
}

const removeAll = async (platformClient: PlatformClient) => {
    const oAuthApi = new platformClient.OAuthApi()
    const clients = await setInactiveClients(platformClient)

    return getExistingOauthClients(platformClient).then((instances) => {
        const clientDeletions = []

        if (instances.length > 0) {
            instances
                .map((entity) => entity.id)
                .forEach((cid) => {
                    clientDeletions.push(oAuthApi.deleteOauthClient(cid))
                })
        }
        return Promise.all(clientDeletions)
            .then(() => {
                return clients
            })
            .catch((err) => {
                captureException(err, `Validation failed`)
            })
    })
}

const createAll = (
    platformClient: PlatformClient,
    roleIds: string[]
): Promise<Record<string, Models.OAuthClient>> => {
    if (!config.provisioningInfo.hasOwnProperty('oauth-client')) {
        return Promise.resolve({})
    }

    const oAuthApi = new platformClient.OAuthApi()
    const authData = {}
    const oauthClientConfigs = config.provisioningInfo['oauth-client']

    const authPromises = []

    const divisions = []

    roleIds.forEach((roleId) => {
        divisions.push({
            roleId: roleId,
            divisionId: '*'
        })
    })

    oauthClientConfigs.forEach((oauthClientConfig) => {
        const oauthClientPayload = {
            name: config.prefix + oauthClientConfig.name,
            description: oauthClientConfig.description,
            authorizedGrantType: oauthClientConfig.authorizedGrantType,
            roleIds: roleIds,
            roleDivisions: divisions
        }

        authPromises.push(
            oAuthApi
                .postOauthClients(oauthClientPayload)
                .then((data) => {
                    authData[oauthClientConfig.name] = data
                })
                .catch((err) => {
                    captureException(err, `Validation failed`)
                })
        )
    })
    return Promise.all(authPromises).then(() => authData)
}

const configure = (
    platformClient: PlatformClient,
    installedData: unknown,
    installedRoleId: string
) => {
    const oAuthApi = new platformClient.OAuthApi()
    const usersApi = new platformClient.UsersApi()

    const promiseArr = []
    const oauthKey = config.provisioningInfo['oauth-client'][0].name
    const oauthData = installedData

    const promise = new Promise((resolve, reject) => {
        const oauth = oauthData[oauthKey]
        const oauthInstall = config.provisioningInfo['oauth-client'][0]

        const timer = setInterval(() => {
            usersApi
                .getUsersMe({
                    expand: ['authorization']
                })
                .then((result) => {
                    const userRoleIds = result.authorization.roles.map(
                        (u) => u.id
                    )
                    let userAssigned = true

                    // Check if all roles for this client is already assigned to the user
                    oauthInstall.roles.forEach((r) => {
                        if (!userRoleIds.includes(installedRoleId)) {
                            userAssigned = false
                        }
                    })

                    if (userAssigned) {
                        clearInterval(timer)

                        oAuthApi
                            .putOauthClient(oauthData[oauthKey].id, {
                                name: oauth.name,
                                authorizedGrantType: oauth.authorizedGrantType,
                                roleIds: [installedRoleId]
                            })
                            .then(() => {
                                resolve({})
                            })
                            .catch((e) => reject(e))
                    }
                })
                .catch((e) => {
                    clearInterval(timer)

                    console.error(e)
                })
        }, 3000)
    })

    promiseArr.push(promise)

    return Promise.all(promiseArr)
}

export { getExistingOauthClients, removeAll, createAll, configure }
