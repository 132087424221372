/* eslint-disable */
import { ApiClientClass, Models } from 'purecloud-platform-client-v2'
import React from 'react'
import { PlatformClient } from './types'

const platformClient: PlatformClient = require('purecloud-platform-client-v2/dist/node/purecloud-platform-client-v2.js')

const client = platformClient.ApiClient.instance

export enum APP_INSTALLED_STATE {
    UNKNOWN = 'UNKNOWN',
    INSTALLED = 'INSTALLED',
    NOT_INSTALLED = 'NOT_INSTALLED'
}

export const GENESYS_INSTALL_PATH = '/calls/genesys/install'
export const GENESYS_ADMIN_PATH = '/calls/genesys/admin'
export const GENESYS_HELP_PATH = '/calls/genesys/help'
export const GENESYS_RESET_PATH = '/calls/genesys/reset'
export const GENESYS_PILOT_PATH = '/calls/pilot/reset'
export const GENESYS_CLIENT_AUTH_PATH = '/calls/genesys/client-auth'
export const GENESYS_REPORTING_PATH = '/calls/genesys/reporting'
export const GENESYS_ACCESS_TOKEN = 'GENESYS_ACCESS_TOKEN'
export const GENESYS_ACCESS_TOKEN_EXPIRY = 'GENESYS_ACCESS_TOKEN_EXPIRY'

export const INBOUND_FLOW_NAME = 'Thrive Inbound'
export const OUTBOUND_FLOW_NAME = 'Thrive Outbound'
export const SCRIPT_NAME = 'ThriveReset'

export type GenesysContextValue = {
    platformClient: PlatformClient
    client: ApiClientClass
    relay: string
    user: Models.UserMe
    installedObjects: unknown[]
    installedStatus: string
    isProductAvailable: boolean
    token: string
}

const GenesysContext = React.createContext<GenesysContextValue>({
    platformClient: {} as unknown as PlatformClient,
    client: {} as unknown as ApiClientClass,
    relay: '',
    user: {
        id: undefined
    } as Models.UserMe, // TODO: fix: missing required filed version
    installedObjects: [],
    installedStatus: '',
    isProductAvailable: false,
    token: null
})

const GenesysContextUpdater = React.createContext({
    setUser: (user: object) => {},
    setInstalledObjects: (installedObjects: []) => {},
    setRelay: (relay: string) => {},
    setInstalledStatus: (status: APP_INSTALLED_STATE) => {},
    setIsProductAvailable: (isProductAvailable: boolean) => {}
})

export { platformClient, client, GenesysContext, GenesysContextUpdater }
