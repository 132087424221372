import { Grid, Link, Stack } from '@mui/material'
import {
    CoreTypography,
    PageLayout,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import LogoSmall from '../assets/LogoSmall'
import { CONTACT_EMAIL } from '../constants/contact'

const HelpPage = () => {
    const theme = useTheme()
    return (
        <PageLayout>
            <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh' }}
            >
                <Grid item textAlign={'center'}>
                    <LogoSmall />
                    <Stack sx={{ mt: theme.spacing(1) }}>
                        <CoreTypography variant="body1" align="center">
                            <FormattedMessage
                                defaultMessage="If you need assistance with the installation of
                                Thrive For Genesys, please contact"
                                description="Genesys help message"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1" align="center">
                            <Link href={`mailto:${CONTACT_EMAIL}`}>
                                {CONTACT_EMAIL}
                            </Link>
                            .
                        </CoreTypography>
                    </Stack>
                </Grid>
            </Grid>
        </PageLayout>
    )
}

export default React.memo(HelpPage)
