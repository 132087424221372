const saveItem = (k, v) => {
    sessionStorage.setItem(k, v)
    return v
}

const getItem = (k) => {
    return sessionStorage.getItem(k)
}

const removeItem = (k) => {
    return sessionStorage.removeItem(k)
}

const clearItems = () => {
    return sessionStorage.clear()
}

export { saveItem, getItem, removeItem, clearItems }
