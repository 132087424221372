export default {
    clientID: '116872ad-f285-4a4d-abab-396b038f3068',

    wizardUriBase: 'https://app.thriveglobal.com/calls/genesys/install',
    //wizardUriBase: 'http://localhost:3000/calls/genesys/install',
    // The actual URL of the landing page of your web app.
    // 'premiumAppURL': 'http://localhost:8080/premium-app-sample/index.html',
    premiumAppURL: 'https://app.thriveglobal.com/calls/genesys/admin',
    //premiumAppURL: 'http://localhost:3000/calls/genesys/admin',
    // Genesys Cloud assigned name for the premium app
    // This should match the integration type name of the Premium App
    // NOTE: During initial development please use ‘premium-app-example’.
    //            Once your premium app is approved an integration type will be created
    //            by the Genesys Cloud product team and you can update the name at that time.
    appName: 'premium-app-thrive',

    sandboxAppName: 'Thrive',

    // The minimum permission required for a user to access the Premium App.
    // NOTE: During initial development please use the default permission
    //      'integration:thriveCallsPremiumApp:view'. Once your premium app is approved,
    //      the unique integration domain will be generated and this must be updated.
    viewPermission: 'integration:thrive:view',

    // Default Values for fail-safe/testing. Shouldn't have to be changed since the app
    // must be able to determine the environment from the query parameter
    // of the integration's URL
    defaultPcEnvironment: 'usw2.pure.cloud',
    defaultLanguage: 'en-us',

    // The names of the query parameters to check in
    // determining language and environment
    // Ex: www.electric-sheep-app.com?langTag=en-us&environment=mypurecloud.com
    languageQueryParam: 'langTag',
    genesysCloudEnvironmentQueryParam: 'environment',
    scriptQueryParam: 'scriptId',
    queueQueryParam: 'queueId',

    // Permissions required for running the Wizard App
    setupPermissionsRequired: ['admin'],

    // Enable the optional 'Step 2' in the porivisoning process
    // If false, it will not show the page or the step in the wizard
    enableCustomSetup: false,

    // To be added to names of Genesys Cloud objects created by the wizard
    prefix: 'THRIVE_',

    // These are the Genesys Cloud items that will be added and provisioned by the wizard
    provisioningInfo: {
        role: [
            {
                name: 'Thrive OAuth Client Role',
                description:
                    'Role containing permissions needed by Thrive for querying and analytics.',
                permissionPolicies: [
                    {
                        domain: 'integration',
                        entityName: 'premium-app-thrive',
                        actionSet: ['*'],
                        allowConditions: false
                    },
                    {
                        domain: 'conversation',
                        entityName: '*',
                        actionSet: ['*'],
                        allowConditions: false
                    },
                    {
                        domain: 'analytics',
                        entityName: 'userObservation',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'routing',
                        entityName: 'queue',
                        actionSet: ['view', 'search', 'edit', 'add', 'delete'],
                        allowConditions: false
                    },
                    {
                        domain: 'routing',
                        entityName: 'queueMember',
                        actionSet: ['manage'],
                        allowConditions: false
                    },
                    {
                        domain: 'routing',
                        entityName: 'skill',
                        actionSet: ['view', 'manage'],
                        allowConditions: false
                    },
                    {
                        domain: 'analytics',
                        entityName: 'agentConversationDetail',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'analytics',
                        entityName: 'userAggregate',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'analytics',
                        entityName: 'conversationAggregate',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'analytics',
                        entityName: 'conversationDetail',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'analytics',
                        entityName: 'userDetail',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'authorization',
                        entityName: 'grant',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'routing',
                        entityName: 'wrapupCode',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'scripter',
                        entityName: '*',
                        actionSet: ['*'],
                        allowConditions: false
                    },
                    {
                        domain: 'oauth',
                        entityName: 'client',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'integrations',
                        entityName: 'integration',
                        actionSet: ['view'],
                        allowConditions: false
                    }
                ],
                permissions: [
                    'integration:thrive:view',
                    'authorization:grant:view',
                    'routing:wrapupCode:view',
                    'oauth:client:view',
                    'integrations:integration:view'
                ]
            },
            {
                name: 'Thrive Admin',
                description:
                    'Admins will be able to configure Thrive for their organization.',
                permissionPolicies: [
                    {
                        domain: 'authorization',
                        entityName: 'grant',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'routing',
                        entityName: 'wrapupCode',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'routing',
                        entityName: 'queue',
                        actionSet: ['view', 'edit', 'join'],
                        allowConditions: false
                    },
                    {
                        domain: 'conversation',
                        entityName: 'callback',
                        actionSet: [
                            'cancel',
                            'view',
                            'edit',
                            'create',
                            'assign',
                            'accept'
                        ],
                        allowConditions: false
                    },
                    {
                        domain: 'integration',
                        entityName: 'thrive',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'oauth',
                        entityName: 'client',
                        actionSet: ['view'],
                        allowConditions: false
                    },
                    {
                        domain: 'integrations',
                        entityName: 'integration',
                        actionSet: ['view'],
                        allowConditions: false
                    }
                ],
                permissions: [
                    'integration:thrive:view',
                    'authorization:grant:view',
                    'routing:wrapupCode:view',
                    'oauth:client:view',
                    'integrations:integration:view',
                    'routing:queue:edit',
                    'conversation:callback:create'
                ],
                assignToSelf: true
            }
        ],
        queue: [
            {
                name: 'Thrive Reset Queue',
                description:
                    'Call queue that will be used to route Thrive Reset calls to agents. A default wrap-up code, Thrive Reset, will be assigned to the queue.'
            }
        ],
        'app-instance': [
            {
                name: 'Thrive',
                description:
                    'Reduces agent stress, and improves productivity, in just 60 seconds.',
                url: 'https://app.thriveglobal.com/calls/genesys/admin',
                type: 'standalone'
            }
        ],
        'data-table': [
            {
                name: 'Thrive Reset Data Table',
                description:
                    'Used by the inbound call flow to queue agents for Thrive Reset.',
                referenceKey: 'id',
                customFields: [
                    {
                        name: 'TheAgent',
                        type: 'string',
                        default: 'Agent'
                    }
                ]
            },
            {
                name: 'Thrive Client Configuration',
                description:
                    'Used to store configuration details for the Thrive integration.',
                referenceKey: 'id',
                customFields: [
                    {
                        name: 'callThreshold',
                        type: 'integer',
                        default: 20
                    }
                ]
            }
        ],
        'oauth-client': [
            {
                name: 'Thrive OAuth Client',
                description:
                    'Generated OAuth client to allow offline access to analytics and agent queue placement to power Thrive For Genesys.',
                roles: ['Thrive OAuth Client Role', 'Thrive Admin'],
                authorizedGrantType: 'CLIENT_CREDENTIALS',

                /**
                 * This function is for other processing that needs
                 * to be done after creating an object.
                 * 'finally' is available for all the other
                 * resources configured in this config file.
                 * NOTE: Finally functions must return a Promise.
                 * For Client Credentials, normally it means
                 * passing the details to the backend.
                 * @param {Object} installedData the Genesys Cloud resource created
                 * @returns {Promise}
                 */
                finally: function (installedData) {
                    return new Promise((resolve, reject) => {})
                }
            }
        ]
    }
}
