import { CircularProgress, CssBaseline, ThemeProvider } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { Suspense, lazy } from 'react'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ClientAuth from '../pages/ClientAuth'
import GenesysProvider from '../utils/providers/genesys/genesys'
import { ROUTES } from './constants'

const InstallPage = lazy(() => import('../pages/GenesysInstallPage'))
const UninstallPage = lazy(() => import('../pages/UninstallPage'))
const GenesysAdminPage = lazy(() => import('../pages/GenesysAdminPage'))
const GenesysResetPage = lazy(() => import('../pages/GenesysResetPage'))
const OauthPage = lazy(() => import('../pages/Oauth'))
const HelpPage = lazy(() => import('../pages/HelpPage'))
const PilotResetPage = lazy(() => import('../pages/PilotResetPage'))
const PilotHelpPage = lazy(() => import('../pages/PilotHelpPage'))
const NiceResetPage = lazy(() => import('../pages/NiceResetPage'))
const NiceAdminPage = lazy(() => import('../pages/NiceAdminPage'))
const NiceHelpPage = lazy(() => import('../pages/NiceHelpPage'))
const WebexResetPage = lazy(() => import('../pages/WebexResetPage'))
const WebexAdminPage = lazy(() => import('../pages/WebexAdminPage'))
const WebexInstallPage = lazy(() => import('../pages/WebexInstallPage'))
const WebexHelpPage = lazy(() => import('../pages/WebexHelpPage'))
const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <IntlProvider locale="en-US" defaultLocale="en-US">
                <BrowserRouter>
                    <Suspense fallback={<CircularProgress />}>
                        <Switch>
                            <Route path={ROUTES.GENESYS}>
                                <GenesysProvider>
                                    <Switch>
                                        <Route path={ROUTES.GENESYS_INSTALL}>
                                            <InstallPage />
                                        </Route>
                                        <Route path={ROUTES.GENESYS_UNINSTALL}>
                                            <UninstallPage />
                                        </Route>
                                        <Route path={ROUTES.GENESYS_RESET}>
                                            <GenesysResetPage />
                                        </Route>
                                        <Route path={ROUTES.GENESYS_ADMIN}>
                                            <GenesysAdminPage />
                                        </Route>
                                        <Route
                                            path={ROUTES.GENESYS_OAUTH}
                                            exact
                                        >
                                            <OauthPage />
                                        </Route>
                                        <Route
                                            path={ROUTES.GENESYS_CLIENT_AUTH}
                                            exact
                                        >
                                            <ClientAuth />
                                        </Route>
                                        <Route path={ROUTES.GENESYS_HELP}>
                                            <HelpPage />
                                        </Route>
                                    </Switch>
                                </GenesysProvider>
                            </Route>

                            <Route path={ROUTES.PILOT_RESET}>
                                <PilotResetPage />
                            </Route>

                            <Route path={ROUTES.PILOT_HELP}>
                                <PilotHelpPage />
                            </Route>

                            <Route path={ROUTES.PILOT_HELP}>
                                <PilotHelpPage />
                            </Route>

                            <Route path={ROUTES.NICE_HELP}>
                                <NiceHelpPage />
                            </Route>

                            <Route path={ROUTES.NICE_RESET}>
                                <NiceResetPage />
                            </Route>

                            <Route path={ROUTES.NICE_ADMIN}>
                                <NiceAdminPage />
                            </Route>

                            <Route path={ROUTES.WEBEX_HELP}>
                                <WebexHelpPage />
                            </Route>

                            <Route path={ROUTES.WEBEX_RESET}>
                                <WebexResetPage />
                            </Route>

                            <Route path={ROUTES.WEBEX_ADMIN}>
                                <WebexAdminPage />
                            </Route>

                            <Route path={ROUTES.WEBEX_INSTALL}>
                                <WebexInstallPage />
                            </Route>

                            <Route render={() => <div>no url match</div>} />
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </IntlProvider>
        </ThemeProvider>
    )
}

export default React.memo(Router)
