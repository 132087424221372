import { FallbackProps } from '@thriveglobal/thrive-web-errors'
import React, { useMemo } from 'react'
import { CallsPlatformType } from '../../constants/callsPlatformType'
import ErrorBoundaryWrapper from '../ErrorBoundaryWrapper'
import { CallsFallbackProps } from '../types'
import DefaultPageFallback from './PageFallback'

export type WithPageFallbackProps = {
    pageName: string
    PageFallback?:
        | React.ComponentType<CallsFallbackProps>
        | React.ComponentType<FallbackProps>
    platformType?: CallsPlatformType
}

function withPageFallback<T>(
    Page: React.ComponentType<T>,
    { pageName, platformType, PageFallback }: WithPageFallbackProps
) {
    const FallbackComponentWrapper = React.memo((props: FallbackProps) => {
        const FallbackComponent = useMemo<
            React.ComponentType<CallsFallbackProps>
        >(() => PageFallback ?? DefaultPageFallback, [])

        return <FallbackComponent {...props} platformType={platformType} />
    })

    return React.memo(function PageWithFallback(props: T) {
        return (
            <ErrorBoundaryWrapper
                boundaryName={pageName}
                boundaryType={'Page'}
                fallbackComponent={FallbackComponentWrapper}
            >
                <Page {...props} />
            </ErrorBoundaryWrapper>
        )
    })
}

export default withPageFallback
