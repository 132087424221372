/* eslint-disable */
import config from '../config'
import { PlatformClient } from '../types'

const doGetInstalledObjects = (platformClient: PlatformClient) => {
    const integrationsApi = new platformClient.IntegrationsApi()
    return integrationsApi
        .getIntegrations()
        .catch((err) => console.log('integrations error', err))
}

const doGetAvailableIntegrationTypes = (platformClient: PlatformClient) => {
    const integrationsApi = new platformClient.IntegrationsApi()
    const integrationName = config.appName
    const opts = { pageSize: 100 }
    return integrationsApi
        .getIntegrationsTypes(opts)
        .then(({ pageNumber, pageCount, entities }) => {
            let promises = []
            for (let i = pageNumber + 1; i <= pageCount; i++) {
                promises.push(
                    integrationsApi.getIntegrationsTypes({
                        ...opts,
                        pageNumber: i
                    })
                )
            }
            return Promise.all(promises).then((results) => {
                return entities
                    .concat(results.map((result) => result.entities))
                    .flat()
                    .filter((entity) => entity.id === integrationName)
            })
        })
}

const doInstallIntegration = (platformClient: PlatformClient) => {
    const integrationsApi = new platformClient.IntegrationsApi()
}

const doUninstallIntegration = (platformClient: PlatformClient) => {
    const integrationsApi = new platformClient.IntegrationsApi()
}

export { doGetInstalledObjects, doGetAvailableIntegrationTypes }
