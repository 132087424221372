import { DeploymentEnv } from '@thriveglobal/thrive-web-core'

export const isProductionEnv = () => {
    try {
        return process.env.DEPLOYMENT_ENV === DeploymentEnv.production
    } catch {
        return true
    }
}

export const HLS_OVERRIDE_COMPANIES_IDS =
    process.env.HLS_OVERRIDE_COMPANIES_IDS?.split(',') ?? []

export const CLOUDFLARE_COMPANY_ID = process.env.CLOUDFLARE_COMPANY_ID
