/* eslint-disable */
import {
    ApiClientClass,
    ArchitectApi,
    Models
} from 'purecloud-platform-client-v2'
import config from '../config'
import { PlatformClient } from '../types'

const CONFIG_ROW_ID = '0'

/**
 * Get existing data tables based on the prefix
 * @returns {Promise.<Array>} Genesys Cloud Integrations
 */
const getExisting = (platformClient: PlatformClient) => {
    const architectApi = new platformClient.ArchitectApi()
    return architectApi
        .getFlowsDatatables({
            pageSize: 100
        })
        .then((result) => {
            return result.entities.filter((dt) =>
                dt.name.startsWith(config.prefix)
            )
        })
}

/**
 * Delete all existing data tables
 */
const removeAll = (platformClient: PlatformClient) => {
    return getExisting(platformClient).then((dts) => {
        let del_dataTable = []
        const architectApi = new platformClient.ArchitectApi()
        if (dts.length > 0) {
            dts.map((dt) => dt.id).forEach((dt_id) => {
                del_dataTable.push(
                    architectApi.deleteFlowsDatatable(dt_id, {
                        force: true
                    })
                )
            })
        }
        return Promise.all(del_dataTable)
    })
}

/**
 * Add Genesys Cloud instances based on installation data
 */
const createAll = (platformClient: PlatformClient) => {
    const architectApi = new platformClient.ArchitectApi()
    let dataTablePromises = []
    let dataTableData = {}

    // Create the data tables
    config.provisioningInfo['data-table'].forEach((dt) => {
        let dataTableBody: Models.DataTable = {
            $schema: 'http://json-schema.org/draft-04/schema#',
            additionalProperties: false,
            name: config.prefix + dt.name,
            type: 'object',
            schema: {
                $schema: 'http://json-schema.org/draft-04/schema#',
                type: 'object',
                additionalProperties: false,
                required: ['key']
            },
            description: dt.description
        } as unknown as Models.DataTable // TODO: Fix types

        // Create properties object with reference key
        let properties = {
            key: {
                title: dt.referenceKey,
                type: 'string',
                $id: '/properties/key',
                displayOrder: 0
            }
        }

        // Build the custom fields
        dt.customFields.forEach((field, i) => {
            let tempSchema = {
                title: field.name,
                type: field.type,
                $id: '/properties/' + field.name,
                displayOrder: i + 1
            }
            // Add default if specified
            if (field.default) tempSchema['default'] = field.default

            properties[field.name] = tempSchema
        })
        dataTableBody.schema['properties'] = properties

        dataTablePromises.push(
            architectApi.postFlowsDatatables(dataTableBody).then((dt) => {
                dataTableData[dt.name] = dt.id
            })
        )
    })

    return Promise.all(dataTablePromises).then(() => dataTableData)
}

/**
 * Further configuration needed by this object
 * Called after everything has already been installed
 */
const configure = async (
    platformClient: PlatformClient,
    callThreshold: object
) => {
    const dts = await getExisting(platformClient)

    if (dts.length > 0) {
        for (const dt of dts) {
            if (
                dt.name ===
                config.prefix + config.provisioningInfo['data-table'][1].name
            ) {
                const dataRow = await getClientRow(platformClient, dt.id)

                dataRow.callThreshold = callThreshold

                return updateClientRow(platformClient, dt.id, dataRow)
            }
        }
    }
}

/**
 * Temporarily store client data in Genesys until identity is worked out
 * @param installedData
 * @param user
 * @param genesysClient
 * @param platformClient
 */
const registerClient = (
    installedData,
    user,
    genesysClient: ApiClientClass,
    platformClient: PlatformClient
) => {
    const dataTableId = installedData.configDataTableId
    let architectApi = new platformClient.ArchitectApi()
    const dataTableRow = {
        key: CONFIG_ROW_ID,
        callThreshold: 20
    }

    return architectApi
        .postFlowsDatatableRows(dataTableId, dataTableRow)
        .then((data) => {})
}

const getClientRow = (platformClient: PlatformClient, dataTableId: string) => {
    let architectApi = new platformClient.ArchitectApi()
    let opts: ArchitectApi.getFlowsDatatableRowOptions = {
        showbrief: 'false'
    } as unknown as ArchitectApi.getFlowsDatatableRowOptions // TODO: Fix types
    return architectApi
        .getFlowsDatatableRow(dataTableId, CONFIG_ROW_ID, opts)
        .then((data) => {
            return data
        })
}

const updateClientRow = (
    platformClient: PlatformClient,
    dataTableId: string,
    dataRow
) => {
    let architectApi = new platformClient.ArchitectApi()

    const opts = {
        body: dataRow
    }

    return architectApi
        .putFlowsDatatableRow(dataTableId, dataRow.key, opts)
        .then((data) => {
            return data
        })
}

const getClientCallThreshold = async (platformClient: PlatformClient) => {
    const dts = await getExisting(platformClient)

    if (dts.length > 0) {
        for (const dt of dts) {
            if (
                dt.name ===
                config.prefix + config.provisioningInfo['data-table'][1].name
            ) {
                const dataRow = await getClientRow(platformClient, dt.id)
                return dataRow.callThreshold
            }
        }
    } else {
        return process.env.DEFAULT_CALL_THRESHOLD
    }
}

const provisioningInfoKey = 'data-table'

export {
    provisioningInfoKey,
    getExisting,
    removeAll,
    createAll,
    configure,
    registerClient,
    getClientCallThreshold
}
