import { Models, RoutingApi } from 'purecloud-platform-client-v2'
import config from '../config'
import { PlatformClient } from '../types'

/**
 * Creates a wrap up code, returns the code id
 */
const createWrapUpCode = (platformClient: PlatformClient) => {
    const apiInstance = new platformClient.RoutingApi()
    const wrapUpCodeBody = {
        name: 'Thrive Reset'
    }
    return apiInstance
        .postRoutingWrapupcodes(wrapUpCodeBody)
        .then((data) => {
            return data.id
        })
        .catch((err) => {
            console.error(err)
        })
}

/**
 * Creates a queue, returns the queue id
 */
const createQueue = (platformClient: PlatformClient) => {
    const createQueueApiInstance = new platformClient.RoutingApi()
    const queueBody = {
        name: config.provisioningInfo['queue'][0].name,
        description: config.provisioningInfo['queue'][0].description,
        routingRules: [
            {
                operator: 'ANY',
                waitSeconds: 90
            }
        ],
        mediaSettings: {
            callback: {
                alertingTimeoutSeconds: 30,
                serviceLevel: {
                    percentage: 0.8,
                    durationMs: 20000
                }
            }
        },
        acwSettings: {
            wrapupPrompt: 'OPTIONAL'
        },
        skillEvaluationMethod: 'ALL',
        autoAnswerOnly: false,
        enableTranscription: false,
        enableManualAssignment: true,
        agentOwnedRouting: {
            enableAgentOwnedCallbacks: true,
            maxOwnedCallbackHours: 2,
            maxOwnedCallbackDelayHours: 2
        },
        callingPartyName: 'Thrive Reset'
    }
    return createQueueApiInstance
        .postRoutingQueues(queueBody)
        .then((data) => {
            return data.id
        })
        .catch((err) => {
            console.error(err)
        })
}

const getAllQueues = async (platformClient: PlatformClient) => {
    const routingApi = new platformClient.RoutingApi()

    const opts = {
        pageSize: 100,
        sortOrder: 'asc'
    }

    return routingApi.getRoutingQueues(opts).then((data) => {
        if (data.total <= 100) {
            return data.entities.filter(
                (entity) =>
                    entity.name !== config.provisioningInfo['queue'][0].name
            )
        } else {
            const pageNumber = 1
            const pageCount = data.total / 100 + 1
            const promises = []
            for (let i = pageNumber + 1; i <= pageCount; i++) {
                promises.push(
                    routingApi.getRoutingQueues({ ...opts, pageNumber: i })
                )
            }
            return Promise.all(promises).then((results) => {
                return data.entities
                    .concat(results.map((result) => result.entities))
                    .flat()
                    .filter(
                        (entity) =>
                            entity.name !==
                            config.provisioningInfo['queue'][0].name
                    )
            })
        }
    })
}

/**
 * Gets the existing queues on Genesys Cloud based on name
 */
const getExistingQueues = (platformClient: RoutingApi) => {
    const opts = {
        pageNumber: 1, // Number | Page number
        pageSize: 25, // Number | Page size
        sortOrder: 'asc', // String | Note: results are sorted by name.
        name: config.provisioningInfo['queue'][0].name // String | Filter by queue name
    }
    return platformClient
        .getRoutingQueues(opts)
        .then((data) => {
            return data
        })
        .catch((err) => {
            console.error(err)
        })
}

/**
 * Gets the existing wrap up codes on Genesys Cloud based on name
 */
const getWrapUpCodes = (platformClient: RoutingApi) => {
    const opts = {
        pageSize: 25, // Number | Page size
        pageNumber: 1, // Number | Page number
        sortBy: 'name', // String | Sort by
        sortOrder: 'ascending', // String | Sort order
        name: 'Thrive Reset' // String | Wrapup code's name ('Sort by' param is ignored unless this field is provided)
    }
    return platformClient
        .getRoutingWrapupcodes(opts)
        .then((data) => {
            return data
        })
        .catch((err) => {
            console.error(err)
        })
}

/**
 * Creates a queue and a wrap up code, then assigns the code to the queue
 * Returns the queue id
 */
const assignCodeToQueue = async (platformClient: PlatformClient) => {
    const apiCodeInstance = new platformClient.RoutingApi()
    const queue = await createQueue(platformClient)
    const id = await createWrapUpCode(platformClient)
    return apiCodeInstance
        .postRoutingQueueWrapupcodes(
            queue as string,
            [{ id }] as Models.WrapUpCodeReference[]
        )
        .then(() => {
            return queue
        })
        .catch((err) => {
            console.error(err)
        })
}

/**
 * Delete existing queues and wrap up codes from Genesys Cloud org
 */
const removeAll = (platformClient: PlatformClient) => {
    const apiRemoveInstance = new platformClient.RoutingApi()
    const promiseArr = []
    const opts = {
        forceDelete: true // Boolean | forceDelete
    }
    const queuesPromise = new Promise((resolve, reject) => {
        getExistingQueues(apiRemoveInstance)
            .then((instances) => {
                const queueDeletions = []
                if (instances && instances.entities.length > 0) {
                    instances.entities
                        .map((ent) => ent.id)
                        .forEach((queueId) => {
                            queueDeletions.push(
                                apiRemoveInstance.deleteRoutingQueue(
                                    queueId,
                                    opts
                                )
                            )
                        })
                }
                return Promise.all(queueDeletions)
            })
            .then(() => {
                resolve({})
            })
            .catch((e) => reject(e))
    })
    const codesPromise = new Promise((resolve, reject) => {
        getWrapUpCodes(apiRemoveInstance)
            .then((codes) => {
                const queueCodeDeletions = []
                if (codes && codes.entities.length > 0) {
                    codes.entities
                        .map((ent) => ent.id)
                        .forEach((cid) => {
                            queueCodeDeletions.push(
                                apiRemoveInstance.deleteRoutingWrapupcode(cid)
                            )
                        })
                }
                return Promise.all(queueCodeDeletions).then((data) => {
                    return data
                })
            })
            .then(() => {
                resolve({})
            })
            .catch((e) => reject(e))
    })
    promiseArr.push(queuesPromise, codesPromise)
    return Promise.all(promiseArr)
}

export {
    createQueue,
    getExistingQueues,
    createWrapUpCode,
    assignCodeToQueue,
    removeAll,
    getAllQueues,
    getWrapUpCodes
}
