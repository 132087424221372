import React, { useEffect } from 'react'
import { isProductionEnv } from '../../../constants/env'
import { CallsFallbackProps } from '../../types'
import PageFallback from '../PageFallback'
import { CALLS_CRASH_SCREEN_PATH } from './constants'

export type ResetPageFallbackProps = CallsFallbackProps

function ResetPageFallback(props: ResetPageFallbackProps): JSX.Element {
    const { platformType, ...restProps } = props

    useEffect(() => {
        if (isProductionEnv()) {
            window.location.href = CALLS_CRASH_SCREEN_PATH
        }
    }, [])

    return <PageFallback platformType={platformType} {...restProps} />
}

export default React.memo(ResetPageFallback)
