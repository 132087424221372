import { Grid, Link, Stack } from '@mui/material'
import {
    CoreTypography,
    PageLayout,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import LogoSmall from '../../assets/LogoSmall'
import { CallsPlatformType } from '../../constants/callsPlatformType'
import { CONTACT_EMAIL } from '../../constants/contact'
import HelpPage from '../../pages/HelpPage'
import NiceHelpPage from '../../pages/NiceHelpPage'
import PilotHelpPage from '../../pages/PilotHelpPage'
import WebexHelpPage from '../../pages/WebexHelpPage'
import { CallsFallbackProps } from '../types'

export type PageFallbackProps = CallsFallbackProps

function PageFallback(props: PageFallbackProps): JSX.Element {
    const { platformType } = props

    const theme = useTheme()

    if (platformType === CallsPlatformType.Genesys) {
        return <HelpPage />
    } else if (platformType === CallsPlatformType.Nice) {
        return <NiceHelpPage />
    } else if (platformType === CallsPlatformType.Pilot) {
        return <PilotHelpPage />
    } else if (platformType === CallsPlatformType.Webex) {
        return <WebexHelpPage />
    }

    return (
        <PageLayout>
            <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh' }}
            >
                <Grid item textAlign={'center'}>
                    <LogoSmall />
                    <Stack sx={{ mt: theme.spacing(1) }}>
                        <CoreTypography variant="body1" align="center">
                            <FormattedMessage
                                defaultMessage="If you need assistance, please get in touch with"
                                description="Unknown calls platform help message"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1" align="center">
                            <Link href={`mailto:${CONTACT_EMAIL}`}>
                                {CONTACT_EMAIL}
                            </Link>
                            .
                        </CoreTypography>
                    </Stack>
                </Grid>
            </Grid>
        </PageLayout>
    )
}

export default React.memo(PageFallback)
