import { SeverityLevel } from '@sentry/types'
import {
    DD,
    addBreadcrumb,
    addSentryTag,
    captureException,
    captureMessage
} from '@thriveglobal/thrive-web-core'
import { useCallback, useMemo } from 'react'

export type UseTracingResult = {
    setTag: (tag: string, value: string) => void
    captureException: (error: Error, additionInfo?: string) => void
    captureMessage: (message: string, additionInfo?: string) => void
    addBreadcrumb: (
        message: string,
        breadcrumb?: {
            level?: SeverityLevel
            category?: string
            data?: Record<string, any>
        }
    ) => void
}

function useTracing(): UseTracingResult {
    const handleCaptureException = useCallback(
        (error: Error, additionInfo?: string) => {
            console.error(error, additionInfo)
            captureException(error, additionInfo)
        },
        []
    )

    const handleCaptureMessage = useCallback(
        (message: string, additionInfo?: string) => {
            console.log(message, additionInfo)
            captureMessage(message, additionInfo)
        },
        []
    )

    const handleAddBreadcrumb = useCallback(
        (
            message: string,
            breadcrumb: {
                level?: SeverityLevel
                category?: string
                data?: Record<string, any>
            } = {}
        ) => {
            console.info(message, breadcrumb)
            addBreadcrumb({
                message,
                level: 'info' as SeverityLevel,
                ...breadcrumb
            })
        },
        []
    )

    const handleSetTag = useCallback(
        (tag: string, value: string) => {
            try {
                console.info(`${tag}: ${value}`)
                addSentryTag(tag, value)
                DD?.addTrackingLabel(tag, value)
            } catch (error) {
                handleCaptureException(
                    error,
                    `Failed to add tracking label for ${tag} with value ${value}`
                )
            }
        },
        [handleCaptureException]
    )

    return useMemo(
        () => ({
            setTag: handleSetTag,
            addBreadcrumb: handleAddBreadcrumb,
            captureMessage: handleCaptureMessage,
            captureException: handleCaptureException
        }),
        [
            handleSetTag,
            handleAddBreadcrumb,
            handleCaptureMessage,
            handleCaptureException
        ]
    )
}

export default useTracing
